<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ this.titleName }} 상세보기</ion-title>
      <ion-button slot="end" @click="closeModal" color="medium" fill="clear">닫기</ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content :fullscreen="true">
    <ion-item>
      <ion-text> {{ this.remk1 }} </ion-text>
    </ion-item>
    <ion-item>
      <ion-text> {{ this.remk2 }} </ion-text>
    </ion-item>
    <ion-item>
      <ion-text> {{ this.remk3 }} </ion-text>
    </ion-item>
    <ion-item>
      <ion-text> {{ this.remk4 }} </ion-text>
    </ion-item>
    <ion-item>
      <ion-text> {{ this.remk5 }}</ion-text>
    </ion-item>
  </ion-content>
</template>

<script lang="ts">
import { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonButton, IonText, 
  modalController } from "@ionic/vue";
import { defineComponent } from "vue";
export default defineComponent({

  name: "Modal",
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonButton, IonText,
  },
  props: {
    titleName: {
      type: String
    },
    remk1: {
      type: String
    },
    remk2: {
      type: String
    },
    remk3: {
      type: String
    },
    remk4: {
      type: String
    },
    remk5: {
      type: String
    },
  },

  setup() {

    function closeModal() {
      modalController.dismiss();
    }

    return { closeModal }
  }
});
</script>

<style>
.center-button {
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  height: 20%;
}

.record-btn {
  height: 40px;
  width: 120px;
}
</style>