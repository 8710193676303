
import { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonButton, IonText, 
  modalController } from "@ionic/vue";
import { defineComponent } from "vue";
export default defineComponent({

  name: "Modal",
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonButton, IonText,
  },
  props: {
    titleName: {
      type: String
    },
    remk1: {
      type: String
    },
    remk2: {
      type: String
    },
    remk3: {
      type: String
    },
    remk4: {
      type: String
    },
    remk5: {
      type: String
    },
  },

  setup() {

    function closeModal() {
      modalController.dismiss();
    }

    return { closeModal }
  }
});
