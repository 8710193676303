import { Ref, ref } from 'vue';
import { $loading } from '@/api/index';

export interface Offices{
  office_code: string;
  office_name: string;
}

export function office(){
    
    const isConnected = ref(true);
    const officelist: Ref<Offices[]> = ref([]);

      async function fetchOfficeDatas() {
        await $loading(`/rtss`, 'GET').then(
          (res: any) => {
            officelist.value = res.data;
          }
        );
      }
    
    return { isConnected, fetchOfficeDatas, officelist };
}
