<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button color="medium" @click="clear">Clear</ion-button>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button color="medium" @click="cancel">Cancel</ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-item>
      <ion-input class="inputNm" type="text" placeholder="영업소를 입력하세요." @input="searchGroup($event)"></ion-input>
    </ion-item>
  </ion-header>
  <ion-content class="my-modal-class4">
    <ion-list>
      <ion-item class="group-list" v-for="detail in officelist" :key="detail">
        <ion-label ref="input" class="officeName" @click="sendSaleNm(detail.office_name)"> {{ detail.office_name }}</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonToolbar,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonList,
  modalController,
  IonHeader,
  IonButtons,
} from "@ionic/vue";
import { reactive, defineComponent } from 'vue';
import { office } from '@/api/services/office';

export default defineComponent({
  components: {
    IonContent,
    IonToolbar,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonList,
    IonHeader,
    IonButtons
  },
  methods: {
    cancel() {
      return modalController.dismiss(null, 'cancel');
    },
    clear() {
      return modalController.dismiss(null, 'clear');
    },
    sendSaleNm(salenm) {
      return modalController.dismiss(salenm, 'confirm');
    },
  },
  setup() {
    const { fetchOfficeDatas, officelist } = office();

    fetchOfficeDatas();

    const detail = reactive({
      officeName: ''
    });

    async function searchGroup(event) {
      const filter = event.target.value;

      const officeNames = Array.from(document.querySelectorAll('.officeName'));
      const groupLists = Array.from(document.querySelectorAll('.group-list'));

      this.officelist.forEach((item, index) => {
        const shouldShow = item.office_name.includes(filter);
        officeNames[index].style.display = shouldShow ? "flex" : "none";
        groupLists[index].style.display = shouldShow ? "flex" : "none";
      });
    }

    return { fetchOfficeDatas, officelist, detail, searchGroup }
  },

});
</script>

<style scoped>
.search-input {
  display: block;
  padding: 4px 8px;
  margin: 10px auto;
  width: 300px;
  font-size: 16px;
  outline: none;
}

.group-list {
  margin: auto;

  display: flex;
}

.group-list ion-label {
  display: flex;
  justify-content: left;
  align-items: left;
  height: 32px;
  list-style-type: none;
}
</style>
